import { GET_GAMES } from '../actions/lastfgamActions';

const lastfGamReducer = (state = [], { type, payload }) => {
	switch (type) {
		case GET_GAMES:
			return payload;
		default:
			return state;
	}
};
export default lastfGamReducer;
