import React, { Component } from "react";
import { toast } from "react-toastify";
import auth from "../../services/authService";
var Joi = require("joi-browser");

const schema = Joi.object().keys({
  password: Joi.string().min(8).max(10).required(),
  newpassword: Joi.string()
    .min(8)
    .max(10)
    .required()
    .options({ language: { any: { allowOnly: "must match password" } } }),
});
class ChangePassword extends Component {
  state = {
    password: "",
    newpassword: "",
  };
  handleChange = async (evt) => {
    await this.setState({ [evt.target.name]: evt.target.value });
  };
  create = async () => {
    toast.configure();
    const { password, newpassword } = this.state;
    const validata = Joi.validate({ password, newpassword }, schema, function (
      err,
      value
    ) {
      if (!err) return null;
      const reterr = err.details[0].message;
      return reterr;
    });
    if (!!validata) {
      toast.error(validata, {
        position: toast.POSITION.BOTTOM_LEFT,
      });

      setTimeout(async () => {
        await this.setState({
          password: "",
          newpassword: "",
        });
      }, 2000);
    } else {
      try {
        const obj = {
          oldpassword: password,
          newpassword: newpassword,
        };
        const data = await auth.changepass(obj);
        if (data.data.success) {
          toast.success(data.data.success, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          this.props.history.push("/home");
        }
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.error(ex.response.data, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setTimeout(async () => {
            await this.setState({ password: "", newpassword: "" });
          }, 2000);
        }
      }
    }
  };
  render() {
    return (
      <React.Fragment>
        <div class="container googleadd-top">
          <div class="row">
            <div class="col-md-12">
              <section id="add-vertical" />
            </div>
          </div>
        </div>
        <section id="mid-section justifycenter">
          <div class="container">
            <div class="register-main">
              <div class="signin-row clearfix">
                <div class="left-squareadd" id="" />

                <div class="register-box">
                  <div class="registerbox-inner">
                    <div class="register-form">
                      <div class="forgot-password">
                        <div id="error_msg" />
                        <h1>Change Password</h1>
                        <div class="forgotpass-tips">
                          <p>Change Your Existing Password Here !,</p>
                        </div>
                        <div class="fgt-formbottom">
                          <div class="mck-form-group">
                            <div class="input-group">
                              {" "}
                              <span class="input-group-addon">
                                <i class="fa fa-key fa-fw" />
                              </span>
                              <input
                                type="password"
                                class="form-control"
                                name="password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                placeholder="Enter Old Password"
                              />
                            </div>
                          </div>
                          <div class="mck-form-group">
                            <div class="input-group">
                              {" "}
                              <span class="input-group-addon">
                                <i class="fa fa-key fa-fw" />
                              </span>
                              <input
                                type="password"
                                class="form-control"
                                name="newpassword"
                                onChange={this.handleChange}
                                value={this.state.newpassword}
                                placeholder="New Password"
                              />
                            </div>
                          </div>
                          <div class="getstarted">
                            <button
                              onClick={this.create}
                              class="btn btn-started"
                            >
                              Change Password
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="right-squareadd text-center" />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default ChangePassword;
