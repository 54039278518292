import React from "react";
import "./App.css";
import Header from "./common/header";
import { Switch, Route } from "react-router-dom";
import Home from "./components/home/home";
import Forgot from "./components/forgotpassword/forgot";
import ResetPassword from "./components/forgotpassword/resetpassword";
import ProtectedRoute from "./components/protectedRoute";
import Tambola from "./components/tambola/tambola";
import ChangePassword from "./components/changepassword/changepassword";
import BuyOneTicket from "./components/tambola/buyoneticket/buyoneticket";
import BuyTwoTickets from "./components/tambola/buytwoticket/buytwoticket";
import Transactions from "./components/transactions/transactions";
import AddFunds from "./components/addfunds/addfunds";
import LastgameWinners from "./components/tambola/lastgamewinners";
import Withdraw from "./components/withdraw/withdraw";

function App() {
	return (
		<React.Fragment>
			<Header />
			<Switch>
				<Route exact path="/" component={Home} />
				<ProtectedRoute exact path="/home" component={Home} />
				<Route exact path="/forgot" component={Forgot} />
				<Route exact path="/reset/:id" component={ResetPassword} />
				<Route exact path="/tambola" component={Tambola} />
				<ProtectedRoute exact path="/buyoneticket" component={BuyOneTicket} />
				<ProtectedRoute exact path="/buytwoticket" component={BuyTwoTickets} />
				<ProtectedRoute
					exact
					path="/changepassword"
					component={ChangePassword}
				/>
				<ProtectedRoute exact path="/transactions" component={Transactions} />
				<ProtectedRoute exact path="/addfunds" component={AddFunds} />
				<ProtectedRoute exact path="/withdraw" component={Withdraw} />
				<Route exact path="/lastgamewinners" component={LastgameWinners} />
			</Switch>
		</React.Fragment>
	);
}

export default App;
