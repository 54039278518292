import store from '../index';
import auth from '../../services/authService';
export var GET_BALANCE = 'GET_BALANCE';

function get_balance() {
	setTimeout(async () => {
		try {
			const { data } = await auth.getbalance();
			store.dispatch({ type: GET_BALANCE, payload: data.success.balance });
		} catch (error) {}
	}, 100);
}

export default get_balance;
