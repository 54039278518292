import React, { Component } from "react";

class Navbar extends Component {
	state = {};
	render() {
		return (
			<React.Fragment>
				<div class="container">
					<div class="row">
						<div class="col-md-12">
							<div id="navigation">
								<nav class="navbar">
									<div class="navbar-header">
										<button class="navbar-toggle" type="button">
											{" "}
											<span class="sr-only">Toggle navigation</span>{" "}
											<span class="icon-bar"></span>{" "}
											<span class="icon-bar"></span>{" "}
											<span class="icon-bar"></span>{" "}
										</button>
									</div>
									{/* <div class="navbar-collapse js-navbar-collapse"> */}
									<ul class="nav navbar-nav">
										<li class="dropdownmain">
											{" "}
											<a
												rel="nofollow"
												href="login.html"
												class="dropdown-toggle"
												data-toggle="dropdownmain"
												role="button"
												aria-expanded="false"
											>
												My Inbox
											</a>
										</li>
										<li class="dropdownmain">
											{" "}
											<a
												href="tambola/index.html"
												class="dropdown-toggle"
												data-toggle="dropdownmain"
												role="button"
												aria-expanded="false"
											>
												Tambola
											</a>
											<ul class="dropdown-menu" role="menu">
												<li>
													<a href="tambola/index795a.html?how-to-play">
														How to Play
													</a>
												</li>
												<li>
													<a href="tambola/index.html">Play New Game</a>
												</li>
												<li>
													<a href="tambola/closed-tambola.html">
														Closed Game Results
													</a>
												</li>
												<li>
													<a target="_blank" href="login.html">
														Invite Friends & Earn
													</a>
												</li>
											</ul>
										</li>
										<li class="dropdownmain">
											{" "}
											<a
												href="quiz/index.html"
												class="dropdown-toggle"
												data-toggle="dropdownmain"
												role="button"
												aria-expanded="false"
											>
												Quiz
											</a>
											<ul class="dropdown-menu" role="menu">
												<li>
													<a href="quiz/index795a.html?how-to-play">
														How to Play
													</a>
												</li>
												<li>
													<a href="quiz/index.html">Play New Game</a>
												</li>
												<li>
													<a href="quiz/closed-quiz.html">
														Closed Game Results
													</a>
												</li>
												<li>
													<a href="contribute/index.html">
														Contribute a Question
													</a>
												</li>
												<li>
													<a href="login.html">My Contributed Questions</a>
												</li>
												<li>
													<a target="_blank" href="login.html">
														Invite Friends & Earn
													</a>
												</li>
											</ul>
										</li>
										<li class="dropdownmain">
											{" "}
											<a
												href="sudoku/index.html"
												class="dropdown-toggle"
												data-toggle="dropdownmain"
												role="button"
												aria-expanded="false"
											>
												Sudoku
											</a>
											<ul class="dropdown-menu" role="menu">
												<li>
													<a href="sudoku/closed-sudoku.html">
														Closed Game Results
													</a>
												</li>
												<li>
													<a href="sudoku/sudoku-contest.html">
														Great Sudoku Contest
													</a>
												</li>
												<li>
													<a target="_blank" href="login.html">
														Invite Friends & Earn
													</a>
												</li>
											</ul>
										</li>
										<li class="dropdownmain">
											{" "}
											<a
												href="unjumble/index.html"
												class="dropdown-toggle"
												data-toggle="dropdownmain"
												role="button"
												aria-expanded="false"
											>
												Five Minute Games
											</a>
											<ul class="dropdown-menu" role="menu">
												<li>
													<a href="unjumble/closed-unjumble.html">
														Closed Unjumble Games
													</a>
												</li>
												<li>
													<a href="guess_image/closed-image.html">
														Closed Guess The Image Games
													</a>
												</li>
												<li>
													<a target="_blank" href="login.html">
														Invite Friends & Earn
													</a>
												</li>
											</ul>
										</li>
										<li class="dropdownmain">
											<a href="cricket/index.html">Cricket </a>
											<ul class="dropdown-menu" role="menu">
												<li>
													<a href="cricket/how-to-play.html">How to Play</a>
												</li>
												<li>
													<a href="cricket/index.html">Play Contest</a>
												</li>
												<li>
													<a href="cricket/closed-contest.html">
														Closed Matches
													</a>
												</li>
											</ul>
										</li>
										<li class="dropdownmain">
											<a href="movies/index.html">Movie Reviews </a>
											<ul class="dropdown-menu" role="menu">
												<li>
													<a href="movies/running-movies/index.html">
														Now Showing
													</a>
												</li>
												<li>
													<a href="movies/upcoming-movies/index.html">
														Coming Soon
													</a>
												</li>
												<li>
													<a href="movies/past-movies/index.html">
														Past Movies
													</a>
												</li>
											</ul>
										</li>
										<li class="dropdownmain mega-dropdown">
											<a href="reviews/index.html">Product Reviews </a>
											<ul class="dropdown-menu mega-dropdown-menu">
												<li class="col-sm-3">
													<ul>
														<li class="dropdown-header">Top Brands</li>
														<li>
															<a href="reviews/apple/index.html">Apple</a>
														</li>
														<li>
															<a href="reviews/htc/index.html">HTC</a>
														</li>
														<li>
															<a href="reviews/micromax/index.html">Micromax</a>
														</li>
														<li>
															<a href="reviews/motorola/index.html">Motorola</a>
														</li>
														<li>
															<a href="reviews/samsung/index.html">Samsung</a>
														</li>
														<li>
															<a href="reviews/sony/index.html">Sony</a>
														</li>
													</ul>
												</li>
												<li class="col-sm-3">
													<ul>
														<li class="dropdown-header">
															New and Popular Models
														</li>
														<li>
															<a href="reviews/samsung-galaxy-star-pro-gt-s7262.html">
																Samsung Galaxy Star Pro Gt-s7262
															</a>{" "}
														</li>
														<li>
															<a href="reviews/apple-iphone-5c-8gb.html">
																Apple iPhone 5C 8GB
															</a>{" "}
														</li>
														<li>
															<a href="reviews/micromax-canvas-win-w121.html">
																Micromax Canvas Win W121
															</a>{" "}
														</li>
														<li>
															<a href="reviews/nokia-lumia-530-dual-sim.html">
																Nokia Lumia 530 Dual SIM
															</a>{" "}
														</li>
														<li>
															<a href="reviews/moto-e.html">Moto E</a>{" "}
														</li>
														<li>
															<a href="reviews/htc-one-m8-gold.html">
																HTC One M8 (Gold)
															</a>{" "}
														</li>
													</ul>
												</li>
												<li class="col-sm-3">
													<ul>
														<li class="dropdown-header">&nbsp;</li>
														<li>
															<a href="reviews/sony-xperia-tipo-dual-sim-black.html">
																Sony Xperia Tipo Dual SIM Black
															</a>{" "}
														</li>
														<li>
															<a href="reviews/asus-zenfone-5-a501cg.html">
																Asus Zenfone 5 A501CG
															</a>{" "}
														</li>
														<li>
															<a href="reviews/lenovo-s850.html">Lenovo S850</a>{" "}
														</li>
														<li>
															<a href="reviews/redmi-1s.html">Redmi 1S</a>{" "}
														</li>
														<li>
															<a href="reviews/karbonn-a55-dual-sim.html">
																Karbonn A55 Dual SIM
															</a>{" "}
														</li>
														<li>
															<a href="reviews/blackberry-z3.html">
																Blackberry Z3
															</a>{" "}
														</li>
													</ul>
												</li>
												<li class="col-sm-3">
													<ul>
														<li class="dropdown-header">Featured Mobile</li>
														<li>
															<a href="reviews/panasonic-eluga-s.html">
																Panasonic Eluga S
															</a>
														</li>
														<li>
															<a href="reviews/apple-iphone-6-with-16-gb.html">
																Apple iPhone 6 with 16 GB
															</a>
														</li>
														<li>
															<a href="reviews/nokia-e6-00-silver.html">
																Nokia E6-00 (Silver)
															</a>
														</li>
														<li>
															<a href="reviews/apple-iphone-5-32-gb.html">
																Apple iPhone 5 32 GB
															</a>
														</li>
														<li>
															<a href="reviews/samsung-galaxy-grand-2-black.html">
																Samsung Galaxy Grand 2 Black
															</a>
														</li>
														<li>
															<a href="reviews/nokia-lumia-1020-white.html">
																Nokia Lumia 1020 (White)
															</a>
														</li>
													</ul>
												</li>{" "}
											</ul>
										</li>
										<li>
											<a href="index.html#howit-works">HOW IT WORKS </a>
										</li>
										<li>
											<a href="courier-details.html">WINNERS </a>
										</li>
									</ul>
									{/* </div> */}
								</nav>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Navbar;
