import { GET_FINGAMS } from '../actions/fingamActions';

const lastfingamReducer = (state = [], { type, payload }) => {
	switch (type) {
		case GET_FINGAMS:
			return payload;
		default:
			return state;
	}
};
export default lastfingamReducer;
