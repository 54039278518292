import React, { Component } from "react";
import { connect } from "react-redux";
import avatar from "../../../assets/images/avatar.png";
import ticketiconwhite from "../../../assets/images/ticket-icon.png";
import gamecom from "../../../assets/images/gamecom.png";
import ticketicon from "../../../assets/images/ticket-icongrey.png";
import clock from "../../../assets/images/clock-icon.png";
import clockwhite from "../../../assets/images/clock1.png";
import participants from "../../../assets/images/participants-icon.png";
import auth from "../../../services/authService";
import Moment from "react-moment";
import ReactCountdownClock from "react-countdown-clock";
import get_gameticks from "../../../redux/actions/gametickActions";
import get_penresgame from "../../../redux/actions/pendresGamActions";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import get_fingams from "../../../redux/actions/fingamActions";

class BuyOneTicket extends Component {
  state = {
    number: "",
    arr: [],
    game: {},
    ticket: [],
    gameseq: [],
    tickolineo: [],
    tickolinet: [],
    tickolineth: [],
    fulltick: [],
    ct: true,
    winners: [],
    gamecomplete: false,
    loader: false,
    tick1sel: false,
    buttondisable: false,
    fullwin: "",
    fullbutton: false,
  };
  ticketselect = async (number) => {
    await this.setState({ tick1sel: true });
    const { gameticks } = this.props;
    const { gameseq } = this.state;
    if (gameseq.indexOf(number) !== -1) {
      const { data } = await auth.seltick({
        ticketid: gameticks[0].ticketid,
        selnum: number,
      });
      this.setState({ arr: data.success, tick1sel: false });
    } else {
      this.setState({ tick1sel: true });
      setTimeout(() => {
        this.setState({ tick1sel: false });
      }, 1000);
    }
  };

  chkwin = async (type) => {
    await this.setState({ buttondisable: true });
    toast.configure();
    const {
      arr,
      gameseq,
      fulltick,
      tickolineo,
      tickolinet,
      tickolineth,
      winners,
    } = this.state;
    const { gameticks, pendres } = this.props;
    const wint = winners.some((win) => win.type === type);
    try {
      if (type === "Early Five") {
        if (
          arr.length >= 5 &&
          arr.every((r) => fulltick.includes(r)) &&
          arr.every((r) => gameseq.includes(r))
        ) {
          if (!wint) {
            const { data } = await auth.appealwin({
              type: type,
              ticketid: gameticks[0].ticketid,
              gameid: pendres.gameid,
            });
            toast.success(data.success, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setTimeout(async () => {
              await this.setState({ buttondisable: false });
            }, 3000);
          } else {
            toast.error("Already Won" + { type }, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setTimeout(async () => {
              await this.setState({ buttondisable: false });
            }, 3000);
            // alert(`Already Won ${type}`);
          }
        } else {
          // alert("Requirement Not Met");
          toast.error("Requirement Not Met", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setTimeout(async () => {
            await this.setState({ buttondisable: false });
          }, 3000);
        }
      } else if (type === "Top Line") {
        if (
          arr.every((r) => fulltick.includes(r)) &&
          arr.every((r) => gameseq.includes(r)) &&
          tickolineo.every((r) => arr.includes(r)) &&
          tickolineo.every((r) => gameseq.includes(r))
        ) {
          if (!wint) {
            const { data } = await auth.appealwin({
              type: type,
              ticketid: gameticks[0].ticketid,
              gameid: pendres.gameid,
            });
            toast.success(data.success, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setTimeout(async () => {
              await this.setState({ buttondisable: false });
            }, 3000);
          } else {
            toast.error("Already Won" + { type }, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setTimeout(async () => {
              await this.setState({ buttondisable: false });
            }, 3000);
          }
        } else {
          toast.error("Requirement Not Met", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setTimeout(async () => {
            await this.setState({ buttondisable: false });
          }, 3000);
        }
      } else if (type === "Middle Line") {
        if (
          arr.every((r) => fulltick.includes(r)) &&
          arr.every((r) => gameseq.includes(r)) &&
          tickolinet.every((r) => arr.includes(r)) &&
          tickolinet.every((r) => gameseq.includes(r))
        ) {
          if (!wint) {
            const { data } = await auth.appealwin({
              type: type,
              ticketid: gameticks[0].ticketid,
              gameid: pendres.gameid,
            });
            toast.success(data.success, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setTimeout(async () => {
              await this.setState({ buttondisable: false });
            }, 3000);
          } else {
            toast.error("Already Won" + { type }, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setTimeout(async () => {
              await this.setState({ buttondisable: false });
            }, 3000);
          }
        } else {
          toast.error("Requirement Not Met", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setTimeout(async () => {
            await this.setState({ buttondisable: false });
          }, 3000);
        }
      } else if (type === "Bottom Line") {
        if (
          arr.every((r) => fulltick.includes(r)) &&
          arr.every((r) => gameseq.includes(r)) &&
          tickolineth.every((r) => arr.includes(r)) &&
          tickolineth.every((r) => gameseq.includes(r))
        ) {
          if (!wint) {
            const { data } = await auth.appealwin({
              type: type,
              ticketid: gameticks[0].ticketid,
              gameid: pendres.gameid,
            });
            toast.success(data.success, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setTimeout(async () => {
              await this.setState({ buttondisable: false });
            }, 3000);
          } else {
            toast.error("Already Won" + { type }, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setTimeout(async () => {
              await this.setState({ buttondisable: false });
            }, 3000);
          }
        } else {
          toast.error("Requirement Not Met", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setTimeout(async () => {
            await this.setState({ buttondisable: false });
          }, 3000);
        }
      } else if (type === "Full House") {
        if (this.state.winners.length > 3) {
          if (
            arr.every((r) => fulltick.includes(r)) &&
            arr.every((r) => gameseq.includes(r)) &&
            fulltick.every((r) => arr.includes(r)) &&
            fulltick.every((r) => gameseq.includes(r))
          ) {
            if (!wint) {
              const { data } = await auth.appealwin({
                type: type,
                ticketid: gameticks[0].ticketid,
                gameid: pendres.gameid,
              });
              // setTimeout(async () => {
              //   await this.setState({ buttondisable: false });
              // }, 3000);
              await this.setState({ gamecomplete: true });
              get_fingams();
              setTimeout(() => {
                window.location = "/home";
              }, 8000);
            } else {
              toast.error(`Already Won ${type}`, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              setTimeout(async () => {
                await this.setState({ buttondisable: false });
              }, 3000);
            }
          } else {
            toast.error("Requirement Not Met", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setTimeout(async () => {
              await this.setState({ buttondisable: false });
            }, 3000);
          }
        } else {
          toast.error("Please Complete Lines First");
          setTimeout(async () => {
            await this.setState({ buttondisable: false });
          }, 3000);
        }
      } else {
        toast.error("Wrong Type Sent", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setTimeout(async () => {
          await this.setState({ buttondisable: false });
        }, 3000);
      }
    } catch (error) {
      if (error && error.response.status === 400) {
        toast.error(error.response.data, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setTimeout(async () => {
          await this.setState({ buttondisable: false });
        }, 3000);
      }
    }
  };
  async componentDidMount() {
    await this.setState({ loader: true });
    await get_gameticks();
    await get_penresgame();
    setTimeout(async () => {
      const { gameticks, pendres } = this.props;
      if (
        pendres &&
        (pendres.status === "Pending" || pendres.status === "Finished")
      ) {
        window.location = "/home";
      } else if (!pendres) {
        window.location = "/home";
      } else {
        if (gameticks && gameticks[0] && pendres) {
          await this.setState({
            gameseq: pendres.sequence,
            winners: pendres.winners,
          });
          await this.setState({
            arr: gameticks[0].selec,
            tickolineo: gameticks[0].ticketseq[0][0].filter((n) => n !== "0"),
            tickolinet: gameticks[0].ticketseq[0][1].filter((n) => n !== "0"),
            tickolineth: gameticks[0].ticketseq[0][2].filter((n) => n !== "0"),
          });
          const { tickolineo, tickolinet, tickolineth } = this.state;
          const fullt = tickolineo.concat(tickolinet, tickolineth);
          await this.setState({ fulltick: fullt });
          //setTimeout(async () => {
          if (this.state.gameseq.length === 0 && pendres) {
            this.nextnum();
          }
          //	}, 2000);
          await this.setState({ loader: false });
        }
      }
    }, 3500);
  }

  nextnum = async () => {
    const { pendres } = this.props;
    await this.setState({ ct: false });
    if (pendres.drawsequence.length > this.state.gameseq.length) {
      const { data } = await auth.getarr(pendres.gameid);

      if (data.winners.some((win) => win.type === "Full House")) {
        await this.setState({
          gamecomplete: true,
        });
        get_fingams();
        setTimeout(() => {
          window.location = "/";
        }, 5000);
      }

      await this.setState({
        gameseq: data.success,
        winners: data.winners,
        fullwin: data.winner,
      });
      await this.setState({ ct: true });
    } else {
      await this.setState({ ct: false });
    }
  };

  render() {
    const { gameticks, pendres } = this.props;
    const {
      gameseq,
      winners,
      arr,
      loader,
      tick1sel,
      gamecomplete,
      ct,
      buttondisable,
    } = this.state;

    return (
      <React.Fragment>
        <section id="mid-section">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div id="play-tambola">
                  <div class="tambola-playbox">
                    <div class="title">
                      <div class="row">
                        <div class=" col-xs-12 col-sm-4 col-md-4">
                          <div class="tgame-no">
                            Game no. {pendres ? pendres.gameid : 64385}
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-8 col-md-8">
                          <div class="game-details">
                            <div class="row">
                              <div class="col-xs-6 col-sm-3 col-md-3">
                                <span class="icon">
                                  <img src={ticketiconwhite} alt="tickets" />
                                </span>
                                <span id="span_tot_tkt">
                                  {pendres ? pendres.tickets.length : 0}
                                </span>{" "}
                                Tickets
                              </div>
                              <div class="col-xs-6 col-sm-3 col-md-3">
                                <span class="icon-p">
                                  <img src={participants} alt="participants" />
                                </span>
                                <span id="span_tot_player">
                                  {pendres ? pendres.participants.length : 0}
                                </span>{" "}
                                Participants
                              </div>
                              <div class="col-xs-6 col-sm-3 col-md-3">
                                <i class="fa fa-calendar-alt mr-2" />
                                <Moment format="D MMM YYYY" withTitle>
                                  {pendres ? pendres.starttime : null}
                                </Moment>
                              </div>
                              <div class="col-xs-6 col-sm-3 col-md-3">
                                <span class="icon-t">
                                  <img src={clockwhite} alt="time" />
                                </span>{" "}
                                <Moment format="hh:mm" withTitle>
                                  {pendres ? pendres.starttime : null}
                                </Moment>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {gamecomplete ? (
                      <div class="text-center">
                        <img class="gamecomplete" src={gamecom} />
                        <h4>Won by {this.state.fullwin} !</h4>
                      </div>
                    ) : loader ? (
                      <div class="text-center">
                        <Loader
                          type="Bars"
                          color="#001563"
                          height={100}
                          width={100}
                          timeout={3500} //3 secs
                        />
                      </div>
                    ) : (
                      <div class="tambola-bottom">
                        <div class="row">
                          <div class="col-xs-4 col-sm-3 col-md-3">
                            <div class="tkt-no">
                              <div class="tkt-no-round">
                                <div class="tkt-no-bg">
                                  {gameseq && gameseq.length > 0
                                    ? gameseq[gameseq.length - 1]
                                    : ""}
                                </div>
                              </div>
                              <div class="no-duration">
                                {" "}
                                <span class="icon-clock">
                                  <img src={clock} alt="time" />
                                </span>
                                Next in{" "}
                                <span class="text-duration">
                                  {ct ? (
                                    <ReactCountdownClock
                                      seconds={10}
                                      color="#000"
                                      alpha={0.9}
                                      size={50}
                                      onComplete={() => this.nextnum()}
                                    />
                                  ) : (
                                    <ReactCountdownClock
                                      seconds={0}
                                      color="#000"
                                      alpha={0.9}
                                      size={50}
                                    />
                                  )}
                                </span>{" "}
                                sec{" "}
                              </div>
                            </div>
                          </div>
                          <div class="col-xs-8 col-sm-9 col-md-9">
                            <div class="number-board">
                              <div class="board-title">Number Board</div>
                              <div class="number-table">
                                {gameseq ? (
                                  <ul class="clearfix">
                                    {gameseq.map((gam, i) => (
                                      <li class="previous">{gam}</li>
                                    ))}
                                  </ul>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="top-winners-list-main">
                          <div class="top-winners">
                            <div class="tam-topinner clearfix">
                              <div class="list_carousel responsive">
                                <div class="caroufredsel_wrapper">
                                  <ul
                                    id="winner_slider"
                                    class="clearfix topdesktop"
                                    style={{
                                      textAlign: "left",
                                      float: "none",
                                      position: "relative",
                                      top: "0px",
                                      left: "0px",
                                      margin: "0px",
                                      width: "1000px",
                                      height: "38px",
                                    }}
                                  >
                                    {winners && winners.length > 0
                                      ? winners.map((obj) => (
                                          <li className="wh-250 cust">
                                            <div class="winner-detail clearfix">
                                              {" "}
                                              <span class="user-pic">
                                                {" "}
                                                <img
                                                  src={avatar}
                                                  alt="winners"
                                                />{" "}
                                              </span>
                                              <h4>{obj.name}</h4>
                                              <p className="font10">
                                                won {obj.type}
                                              </p>
                                            </div>
                                          </li>
                                        ))
                                      : null}
                                  </ul>

                                  <div class="row topmobile">
                                    {winners && winners.length > 0
                                      ? winners.slice(0, 2).map((item, k) => {
                                          return (
                                            <div class="col-md-6 float-none">
                                              <li className="wh-250">
                                                <div class="winner-detail clearfix">
                                                  {" "}
                                                  <span class="user-pic">
                                                    {" "}
                                                    <img
                                                      src={avatar}
                                                      alt="winners"
                                                    />{" "}
                                                  </span>
                                                  <h4>{item.name}</h4>
                                                  <p className="font10">
                                                    won {item.type}
                                                  </p>
                                                </div>
                                              </li>
                                            </div>
                                          );
                                        })
                                      : null}
                                  </div>
                                  <div class="row mt-3 topmobile">
                                    {winners && winners.length > 0
                                      ? winners.slice(2, 4).map((item, k) => {
                                          return (
                                            <div class="col-md-6 float-none">
                                              <li className="wh-250">
                                                <div class="winner-detail clearfix">
                                                  {" "}
                                                  <span class="user-pic">
                                                    {" "}
                                                    <img
                                                      src={avatar}
                                                      alt="winners"
                                                    />{" "}
                                                  </span>
                                                  <h4>{item.name}</h4>
                                                  <p>won {item.type}</p>
                                                </div>
                                              </li>
                                            </div>
                                          );
                                        })
                                      : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {tick1sel ? (
                          <div class="text-center">
                            <Loader
                              type="Bars"
                              color="#001563"
                              height={100}
                              width={100}
                              timeout={3500} //3 secs
                            />
                          </div>
                        ) : (
                          <div class="ticket-box-main">
                            <div class="row">
                              <div class="col-md-6 single-tkt">
                                <div class="tk-boxleft">
                                  <div class="titlebar">
                                    <span class="tkticon">
                                      <img src={ticketicon} />
                                    </span>{" "}
                                    Ticket #{" "}
                                    {gameticks && gameticks.length > 0
                                      ? gameticks[0].ticketid
                                      : 7506427}
                                  </div>
                                  <div class="ticket-table ">
                                    <table class="table table-bordered table-responsive">
                                      {gameticks && gameticks.length > 0
                                        ? gameticks[0].ticketseq.map(
                                            (tick, i) => (
                                              <tbody>
                                                {tick.map((ti) => (
                                                  <tr>
                                                    {ti.map((t) => (
                                                      <td
                                                        className={
                                                          this.state.arr.includes(
                                                            t
                                                          )
                                                            ? "not-active"
                                                            : ""
                                                        }
                                                        onClick={
                                                          t !== "0"
                                                            ? () =>
                                                                this.ticketselect(
                                                                  t
                                                                )
                                                            : null
                                                        }
                                                      >
                                                        <a>
                                                          {t === "0" ? "" : t}
                                                        </a>
                                                      </td>
                                                    ))}
                                                  </tr>
                                                ))}
                                              </tbody>
                                            )
                                          )
                                        : null}
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div class="claim-now-main">
                          <div class="claim-now-main-inner">
                            <div class="titlebar">
                              <span class="tkticon">
                                <img src={ticketicon} />
                              </span>{" "}
                              Ticket #{" "}
                              {gameticks && gameticks.length > 0
                                ? gameticks[0].ticketid
                                : 7506427}
                            </div>
                            <div class="claim-nowlink clearfix">
                              <div className="row">
                                <div className="col-md-4">
                                  <div class="claim-nowleft single-claim-tkt">
                                    <button
                                      type="button"
                                      class="btn btn-claim"
                                      style={{ backgroundColor: "#001563" }}
                                      disabled={
                                        buttondisable ||
                                        (winners &&
                                          winners.length > 0 &&
                                          winners.some(
                                            (win) => win.type === "Early Five"
                                          ))
                                      }
                                      onClick={() => this.chkwin("Early Five")}
                                    >
                                      Fast Five
                                    </button>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div class="claim-nowleft single-claim-tkt">
                                    <button
                                      type="button"
                                      class="btn btn-claim2"
                                      style={{ backgroundColor: "#001563" }}
                                      disabled={
                                        buttondisable ||
                                        (winners &&
                                          winners.length > 0 &&
                                          winners.some(
                                            (win) => win.type === "Top Line"
                                          ))
                                      }
                                      onClick={() => this.chkwin("Top Line")}
                                    >
                                      Top Line
                                    </button>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div class="claim-nowleft single-claim-tkt">
                                    <button
                                      type="button"
                                      class="btn btn-claim2"
                                      style={{ backgroundColor: "#001563" }}
                                      disabled={
                                        buttondisable ||
                                        (winners &&
                                          winners.length > 0 &&
                                          winners.some(
                                            (win) => win.type === "Middle Line"
                                          ))
                                      }
                                      onClick={() => this.chkwin("Middle Line")}
                                    >
                                      Middle Line
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className="row mtop">
                                <div className="col-md-6">
                                  <div class="claim-nowleft single-claim-tkt">
                                    <button
                                      type="button"
                                      class="btn btn-claim2"
                                      style={{ backgroundColor: "#001563" }}
                                      type="button"
                                      class="btn btn-claim2"
                                      disabled={
                                        buttondisable ||
                                        (winners &&
                                          winners.length > 0 &&
                                          winners.some(
                                            (win) => win.type === "Bottom Line"
                                          ))
                                      }
                                      onClick={() => this.chkwin("Bottom Line")}
                                    >
                                      Bottom Line
                                    </button>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div class="claim-nowleft single-claim-tkt">
                                    <button
                                      type="button"
                                      class="btn btn-claim2"
                                      style={{ backgroundColor: "#001563" }}
                                      disabled={
                                        buttondisable ||
                                        (winners &&
                                          winners.length > 0 &&
                                          winners.some(
                                            (win) => win.type === "Full House"
                                          ))
                                      }
                                      onClick={() => this.chkwin("Full House")}
                                    >
                                      Full Housie
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gameticks: state.gameticks,
    pendres: state.pendres,
  };
};

export default connect(mapStateToProps)(BuyOneTicket);
