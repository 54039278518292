import React, { Component } from "react";
import Header from "../../common/header";
import users from "../../services/userservice";
import { toast } from "react-toastify";

var Joi = require("joi-browser");

const schema = Joi.object().keys({
	email: Joi.string().email().required(),
});
class Forgot extends Component {
	state = {
		email: "",
	};
	handleChange = async (evt) => {
		await this.setState({ [evt.target.name]: evt.target.value });
	};
	reset = async () => {
		toast.configure();
		const { email } = this.state;
		const validata = Joi.validate({ email }, schema, function (err, value) {
			if (!err) return null;
			const reterr = err.details[0].message;
			return reterr;
		});
		if (!!validata) {
			toast.error(validata, {
				position: toast.POSITION.BOTTOM_LEFT,
			});

			setTimeout(async () => {
				await this.setState({
					email: "",
				});
			}, 2000);
		} else {
			try {
				const obj = {
					email: email,
				};
				const data = await users.forgot(obj);
				if (data.data) {
					this.setState({ token: data.data });
					this.props.history.push({
						pathname: "/reset",
						state: { token: data.data, type: "reset" },
					});
				}
			} catch (ex) {
				if (ex.response && ex.response.status === 400) {
					toast.error(ex.response.data, {
						position: toast.POSITION.BOTTOM_LEFT,
					});

					setTimeout(async () => {
						await this.setState({ email: "" });
					}, 2000);
				}
			}
		}
	};
	render() {
		return (
			<React.Fragment>
				<div class="container googleadd-top">
					<div class="row">
						<div class="col-md-12">
							<section id="add-vertical" />
						</div>
					</div>
				</div>
				<section id="mid-section justifycenter">
					<div class="container">
						<div class="register-main">
							<div class="signin-row clearfix">
								<div class="left-squareadd" id="" />

								<div class="register-box">
									<div class="registerbox-inner">
										<div class="register-form">
											<div class="forgot-password">
												<div id="error_msg" />
												<h1>Forgot Password</h1>
												<div class="forgotpass-tips">
													<p>If you have forgotten your password,</p>
													<p>
														we can send an email with instructions to reset it.
													</p>
												</div>
												<div class="fgt-formbottom">
													<div class="mck-form-group">
														<div class="input-group">
															{" "}
															<span class="input-group-addon">
																<i class="fa fa-envelope" />
															</span>
															<input
																type="email"
																class="form-control"
																name="email"
																onChange={this.handleChange}
																value={this.state.email}
																placeholder="Email Address"
															/>
														</div>
													</div>
													<div class="getstarted">
														<button
															onClick={this.reset}
															type="submit"
															class="btn btn-started"
														>
															Forgot Password
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="right-squareadd text-center" />
							</div>
						</div>
					</div>
				</section>
			</React.Fragment>
		);
	}
}

export default Forgot;
