import { GET_PENRESGAME } from '../actions/pendresGamActions';

const pendresGamReducer = (state = null, { type, payload }) => {
	switch (type) {
		case GET_PENRESGAME:
			return payload;
		default:
			return state;
	}
};
export default pendresGamReducer;
