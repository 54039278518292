import React, { Component } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import get_balance from "../../redux/actions/balanceActions";
import auth from "../../services/authService";
import get_trans from "../../redux/actions/transActions";
var Joi = require("joi-browser");

const schema = Joi.object().keys({
  amount: Joi.number().required(),
  upiid: Joi.string().required(),
});
class Withdraw extends Component {
  state = {
    amount: "",
    upiid: "",
    buttonsid: false,
  };
  componentDidMount() {
    if (!this.props.balance) {
      get_balance();
    }
  }
  handleChange = async (evt) => {
    await this.setState({ [evt.target.name]: evt.target.value });
  };
  create = async () => {
    await this.setState({ buttonsid: true });
    toast.configure();
    const { amount, upiid } = this.state;
    const validata = Joi.validate({ amount, upiid }, schema, function (
      err,
      value
    ) {
      if (!err) return null;
      const reterr = err.details[0].message;
      return reterr;
    });
    if (!!validata) {
      toast.error(validata, {
        position: toast.POSITION.BOTTOM_LEFT,
      });

      setTimeout(async () => {
        await this.setState({
          amount: "",
          uppiid: "",
          buttonsid: false,
        });
      }, 2000);
    } else {
      if (this.state.amount < 25) {
        toast.error("Minimum Withdraw Amount is 25/-", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setTimeout(async () => {
          await this.setState({ amount: "", buttonsid: false });
        }, 3000);
      } else {
        try {
          if (this.props.balance > this.state.amount) {
            const obj = {
              amount: amount,
              upiid: upiid,
            };

            const data = await auth.withdraw(obj);
            if (data.data.success) {
              toast.success(data.data.success, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              get_trans();
              get_balance();
              this.props.history.push("/transactions");
            }
          } else {
            setTimeout(async () => {
              await this.setState({ amount: "", upiid: "", buttonsid: false });
            }, 3000);

            toast.error("insufficient Funds", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
        } catch (ex) {
          if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setTimeout(async () => {
              await this.setState({ amount: "", upiid: "", buttonsid: false });
            }, 2000);
          }
        }
      }
    }
  };
  render() {
    return (
      <React.Fragment>
        <div class="container googleadd-top">
          <div class="row">
            <div class="col-md-12">
              <section id="add-vertical" />
            </div>
          </div>
        </div>
        <section id="mid-section justifycenter">
          <div class="container">
            <div class="register-main">
              <div class="signin-row clearfix">
                <div class="left-squareadd" id="" />

                <div class="register-box">
                  <div class="registerbox-inner">
                    <div class="register-form">
                      <h5
                        style={{
                          flexDirection: "row",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontSize: "18px" }}>
                          Available Balance :
                        </span>{" "}
                        <span style={{ fontSize: "18px" }}>
                          {this.props.balance ? this.props.balance : 0}
                        </span>
                      </h5>
                      <div class="forgot-password">
                        <h1>Withdraw</h1>
                        <div class="forgotpass-tips">
                          <p>Fill The Form to Withdraw.</p>
                        </div>
                        <div class="fgt-formbottom">
                          <div class="mck-form-group">
                            <div class="input-group">
                              {" "}
                              <span class="input-group-addon">
                                <i class="fa fa-key fa-fw" />
                              </span>
                              <input
                                type="number"
                                class="form-control"
                                name="amount"
                                value={this.state.amount}
                                onChange={this.handleChange}
                                placeholder="Enter Amount"
                              />
                            </div>
                          </div>
                          <div class="mck-form-group">
                            <div class="input-group">
                              {" "}
                              <span class="input-group-addon">
                                <i class="fa fa-key fa-fw" />
                              </span>
                              <input
                                type="text"
                                class="form-control"
                                name="upiid"
                                onChange={this.handleChange}
                                value={this.state.upiid}
                                placeholder="Enter Upi Id"
                              />
                            </div>
                          </div>
                          <div>
                            <span style={{ color: "white" }}>
                              Note: Minimum Withdraw Amount is 25/-
                            </span>
                          </div>
                          <div class="getstarted">
                            <button
                              onClick={this.create}
                              class="btn btn-started"
                              disabled={this.state.buttonsid}
                            >
                              Withdraw
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="right-squareadd text-center" />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    balance: state.balance,
  };
};

export default connect(mapStateToProps)(Withdraw);
