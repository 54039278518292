import React, { Component } from "react";
import Header from "../../common/header";
import Tambola from "../tambola/tambola";

class Home extends Component {
	state = {};
	render() {
		return (
			<React.Fragment>
				<Tambola history={this.props.history} />
			</React.Fragment>
		);
	}
}

export default Home;
