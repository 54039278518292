import store from "../index";
import user from "../../services/userservice";
export var GET_GAMES = "GET_GAMES";

function get_games() {
  setTimeout(async () => {
    try {
      const { data } = await user.lastf();
      store.dispatch({ type: GET_GAMES, payload: data.success });
    } catch (error) {}
  }, 100);
}

export default get_games;
