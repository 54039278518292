import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import auth from "../../services/authService";
import Login from "../login/login";
import topfive from "../../assets/images/tambolatop-five.png";
import closegame from "../../assets/images/tambolaclose-game.png";
import winner from "../../assets/images/tambolawinners.png";
import play from "../../assets/images/tambolahow-to.png";
import clock from "../../assets/images/clock1.png";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import HowToPlay from "./howtoplay";
import get_games from "../../redux/actions/lastfgamActions";
import get_gameticks from "../../redux/actions/gametickActions";
import get_penresgame from "../../redux/actions/pendresGamActions";
import get_balance from "../../redux/actions/balanceActions";
import get_trans from "../../redux/actions/transActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Tambola extends Component {
  state = {
    joinopen: false,
    gamedata: {},
    howtoplayopen: false,
    showticketbutton: true,
    cancelbutton: false,
  };

  async componentDidMount() {
    const { games, gameticks, pendres, balance } = this.props;
    if (games && games.length <= 0) {
      await get_games();
    }
    if (!gameticks) {
      await get_gameticks();
    }
    if (!pendres) {
      await get_penresgame();
    }
    if (!balance) {
      await get_balance();
    }
  }
  resumeGame = async () => {
    toast.configure();
    const { gameticks, pendres } = this.props;
    if (
      gameticks &&
      gameticks.length > 0 &&
      gameticks[0].gameid === pendres.gameid
    ) {
      if (gameticks[0].gameid === pendres.gameid) {
        if (gameticks.length === 1) {
          this.props.history.push("/buyoneticket");
        } else if (gameticks.length === 2) {
          this.props.history.push("/buytwoticket");
        }
      }
    } else {
      toast.error("You Should Buy Tickets Before Resuming Game", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };
  onOpenjoinModal = async () => {
    toast.configure();
    if (auth.getCurrentUser()) {
      const { gameticks, pendres } = this.props;
      if (gameticks && gameticks.length <= 1) {
        this.setState({ joinopen: true });
      } else {
        if (gameticks && gameticks.length === 1) {
          if (pendres.status === "Resume") {
            get_balance();
            get_games();
            get_trans();
            this.props.history.push("/buyoneticket");
          } else {
            toast.error("Game Not Yet Started", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
        } else if (gameticks.length === 2) {
          if (pendres.status === "Resume") {
            await get_balance();
            await get_games();
            await get_trans();
            await get_gameticks();
            this.props.history.push("/buytwoticket");
          } else {
            toast.error("Game Not Yet Started", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
        }
      }
    } else {
      this.setState({ joinopen: true });
    }
  };

  cancelticket = async (gameid) => {
    const { pendres } = this.props;
    var currtim = new Date()
      .toLocaleTimeString("en-US", {
        hour: "numeric",
        hour12: true,
        minute: "numeric",
      })
      .split(" ");

    var gametim = pendres && pendres.startsat.split(" ");

    toast.configure();
    await this.setState({ cancelbutton: true });
    try {
      const { data } = await auth.canceltick(gameid);

      if (data.success) {
        toast.success(data.success, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setTimeout(() => {
          window.location = "/";
        }, 2000);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        await this.setState({ cancelbutton: false });
      }
    }
  };
  onOpenplayModal = () => {
    this.setState({ howtoplayopen: true });
  };
  onCloseplayModal = () => {
    this.setState({ howtoplayopen: false });
  };
  onClosejoinModal = () => {
    this.setState({ joinopen: false });
  };

  buytickets = async (ticket) => {
    await this.setState({ showticketbutton: false });
    toast.configure();
    const { gameticks, pendres, balance } = this.props;
    try {
      if (pendres && gameticks && gameticks.length > 0) {
        if (gameticks[0].gameid === pendres.gameid) {
          if (gameticks.length === 1) {
            if (pendres.status === "Resume") {
              get_balance();
              get_games();
              get_trans();

              this.props.history.push("/buyoneticket");
            } else if (pendres.status === "Pending") {
              if (ticket === "1") {
                var price = 5;
                if (balance && balance >= price) {
                  if (pendres && pendres.gameid) {
                    const { data } = await auth.genticket({
                      num: 1,
                      gameno: pendres.gameid,
                    });
                    await get_gameticks();
                    await get_games();
                    await get_balance();
                    await get_trans();
                    if (pendres.status === "Resume") {
                      this.props.history.push("/buyoneticket");
                    } else {
                      toast.info(
                        "Tickets Bought! Game Not Yet Started! Please Wait for the Game to Start!",
                        {
                          position: toast.POSITION.BOTTOM_LEFT,
                        }
                      );
                      window.location = "/";
                    }
                  }
                } else {
                  toast.error("Insufficient Funds", {
                    position: toast.POSITION.BOTTOM_LEFT,
                  });

                  this.setState({ joinopen: false });
                }
              } else {
                price = 10;
                if (balance && balance >= price) {
                  if (pendres && pendres.gameid) {
                    const { data } = await auth.genticket({
                      num: 2,
                      gameno: pendres.gameid,
                    });
                    await get_games();
                    await get_gameticks();
                    if (pendres.status === "Resume") {
                      await get_balance();
                      await get_trans();
                      this.props.history.push("/buytwoticket");
                    } else {
                      await get_balance();
                      await get_games();
                      await get_trans();
                      await get_gameticks();
                      toast.info(
                        "Tickets Bought! Game Not Yet Started! Please Wait for the Game to Start!",
                        {
                          position: toast.POSITION.BOTTOM_LEFT,
                        }
                      );
                      window.location = "/";
                    }
                  }
                } else {
                  toast.error("Insufficient Funds", {
                    position: toast.POSITION.BOTTOM_LEFT,
                  });
                  this.setState({ joinopen: false });
                }
              }
              window.location = "/";
            }
          } else if (gameticks.length === 2) {
            if (pendres.status === "Resume") {
              await get_balance();
              await get_games();
              await get_trans();
              await get_gameticks();
              this.props.history.push("/buytwoticket");
            } else {
              toast.error("Something Went Wrong", {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              window.location = "/";
            }
          }
        } else {
          toast.error("Something Went Wrong", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      } else {
        if (ticket === "1") {
          var price = 5;
          if (balance && balance >= price) {
            if (pendres && pendres.gameid) {
              const { data } = await auth.genticket({
                num: 1,
                gameno: pendres.gameid,
              });
              setTimeout(async () => {
                await get_gameticks();
                await get_games();
                await get_balance();
                await get_trans();
              }, 2000);

              if (pendres.status === "Resume") {
                this.props.history.push("/buyoneticket");
              } else {
                toast.info(
                  "1 Ticket Bought! Game Not Yet Started! Please Wait for the Game to Start!",
                  {
                    position: toast.POSITION.BOTTOM_LEFT,
                  }
                );
                window.location = "/";
              }
            }
          } else {
            toast.error("Insufficient Funds", {
              position: toast.POSITION.BOTTOM_LEFT,
            });

            this.setState({ joinopen: false });
          }
        } else {
          price = 10;
          if (balance && balance >= price) {
            if (pendres && pendres.gameid) {
              const { data } = await auth.genticket({
                num: 2,
                gameno: pendres.gameid,
              });
              setTimeout(async () => {
                await get_games();
                await get_gameticks();
                await get_balance();
                await get_trans();
              }, 2000);

              if (pendres.status === "Resume") {
                await get_balance();

                await get_trans();

                this.props.history.push("/buytwoticket");
              } else {
                await get_balance();
                await get_games();
                await get_trans();
                await get_gameticks();
                toast.info(
                  "2 Tickets Bought! Game Not Yet Started! Please Wait for the Game to Start!",
                  {
                    position: toast.POSITION.BOTTOM_LEFT,
                  }
                );
                window.location = "/";
              }
            }
          } else {
            toast.error("Insufficient Funds", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            this.setState({ joinopen: false });
          }
        }
      }
    } catch (error) {
      if (error && error.response.status === 400) {
        await this.setState({ showticketbutton: true });
        toast.error(error.response.data, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        this.setState({ joinopen: false });
      }
    }
  };

  gettimediff = () => {
    const { pendres } = this.props;
    var currtim = new Date()
      .toLocaleTimeString("en-US", {
        hour: "numeric",
        hour12: true,
        minute: "numeric",
      })
      .split(" ");
    console.log(parseFloat(currtim[0]));

    var gametim = pendres && pendres.startsat.split(" ");
    console.log(parseFloat(gametim[0]));
  };

  render() {
    // console.log(this.props.games ? this.props.games : "null");

    const { games, pendres, gameticks } = this.props;

    return (
      <React.Fragment>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <section id="add-vertical" />
            </div>
          </div>
        </div>
        <section id="mid-section">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div id="play-tambola">
                  <div class="tambola-playbox">
                    <div class="title tambola-start">
                      <div class="title-row clearfix">
                        <div class="gamenum">
                          <div class="tgame-no">
                            Game no. {pendres ? pendres.gameid : ""}
                          </div>
                        </div>
                        <div class="date-time">
                          <div class="game-details">
                            <div class="clearfix">
                              <div class="take-left">
                                <i class="fa fa-calendar-alt" />
                                <span class="ml-2 current-date">
                                  <Moment format="D MMM YYYY" withTitle />
                                </span>
                              </div>
                              <div class="take-right">
                                <span class="icon-t">
                                  <img src={clock} alt="time" />
                                </span>{" "}
                                <span class=" current-time">
                                  <Moment format="hh:mm" withTitle />{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tambola-start-bottom">
                      <div class="tambola-info">
                        <h1>
                          {pendres && pendres.status === "Resume"
                            ? "Game Started At"
                            : pendres && pendres.status === "Pending"
                            ? "Game Starts At"
                            : null}
                        </h1>
                        <div class="game-startat">
                          <p>
                            {" "}
                            <span class="time">
                              {pendres && pendres.status === "Resume" ? (
                                <Moment format="hh:mm" withTitle>
                                  {pendres.starttime}
                                </Moment>
                              ) : pendres && pendres.status === "Pending" ? (
                                pendres.startsat
                              ) : null}
                            </span>{" "}
                            Today
                            {pendres && pendres.status === "Resume" ? (
                              <div>
                                <h1>
                                  <br />
                                  Total Players : {pendres.participants.length}
                                  <br />
                                  Total Tickets : {pendres.tickets.length}
                                </h1>
                              </div>
                            ) : null}
                          </p>
                        </div>
                        <div
                          onClick={this.onOpenjoinModal}
                          class="tambola-timer"
                        >
                          <a
                            class="timer-bg"
                            data-toggle="modal"
                            data-target="#ModalBoxIndex"
                          >
                            <div class="heading_join">Buy Tickets</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div id="play-tambola">
                  <div class="tambola-result">
                    <div class="tambolawinners-details">
                      <div class="tambola-winners-title">
                        <h1>Last 5 Games</h1>
                      </div>
                      <div class="last-gamewinners">
                        <table class="table table-striped tambola-winners-list">
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center" }} width="10%">
                                S.No
                              </th>
                              <th style={{ textAlign: "center" }} width="23%">
                                Game Id
                              </th>
                              <th style={{ textAlign: "center" }} width="25%">
                                Your Tickets
                              </th>
                              <th style={{ textAlign: "center" }} width="25%">
                                Game Tickets
                              </th>
                              <th
                                style={{ textAlign: "center" }}
                                width="19%"
                                class="hidden_xs"
                              >
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {games.length > 0
                              ? games.map((item, i) => (
                                  <tr>
                                    <td style={{ textAlign: "center" }}>
                                      {i + 1}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {item.gameid}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {item.tickets}{" "}
                                      {item.tickets > 0 &&
                                      item.status === "Pending" ? (
                                        <button
                                          class="btn-sm btn-danger"
                                          onClick={() =>
                                            this.cancelticket(item.gameid)
                                          }
                                          disabled={this.state.cancelbutton}
                                        >
                                          Cancel Tickets
                                        </button>
                                      ) : null}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {item.gametics}
                                    </td>
                                    <td>
                                      {item.status === "Resume" ? (
                                        <div className="resume">
                                          <button
                                            class="btn-sm btn-success"
                                            onClick={() =>
                                              this.resumeGame(item.gameid)
                                            }
                                          >
                                            {" "}
                                            Resume
                                          </button>
                                        </div>
                                      ) : (
                                        item.status
                                      )}
                                    </td>
                                  </tr>
                                ))
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal
          open={this.state.joinopen}
          onClose={this.onClosejoinModal}
          showCloseIcon={false}
          center
        >
          {!auth.getCurrentUser() ? (
            <Login close={this.onClosejoinModal} />
          ) : (
            <div
              id="ModalBoxIndex"
              class="modal buy-ticket"
              style={{ display: "block", paddingLeft: "0px" }}
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <button onClick={this.onClosejoinModal} class="close">
                      ×
                    </button>
                  </div>
                  <div class="modal-body">
                    {this.state.showticketbutton ? (
                      <div>
                        <div
                          onClick={() => this.buytickets("1")}
                          class="ticket-common"
                        >
                          <a>
                            <span>Buy one Ticket</span>{" "}
                            <span>for 5 Rupees</span>
                          </a>
                        </div>

                        <div
                          onClick={() => this.buytickets("2")}
                          class="ticket-common"
                        >
                          <a>
                            <span>Buy two Ticket</span>{" "}
                            <span>for 10 Rupees</span>
                          </a>
                        </div>
                      </div>
                    ) : (
                      <span>Processing ....</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
        <Modal
          open={this.state.howtoplayopen}
          onClose={this.onCloseplayModal}
          showCloseIcon={false}
          center
        >
          <HowToPlay close={this.onCloseplayModal} />
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    games: state.games,
    gameticks: state.gameticks,
    pendres: state.pendres,
    balance: state.balance,
  };
};

export default connect(mapStateToProps)(Tambola);
