import { combineReducers } from 'redux';
import authReducer from './authReducer';
import balanceReducer from './balanceReducer';
import transReducer from './transReducer';
import lastfGamReducer from './lastfGamReducer';
import gametickReducer from './gametickReducer';
import pendresGamReducer from './pendresGamReducer';
import lastfingamReducer from './lastfingamReducer';

export default function allReducers() {
	return combineReducers({
		auth: authReducer,
		balance: balanceReducer,
		trans: transReducer,
		games: lastfGamReducer,
		gameticks: gametickReducer,
		pendres: pendresGamReducer,
		fingams: lastfingamReducer
	});
}
