import React, { Component } from "react";

class HowToPlay extends Component {
	state = {};
	render() {
		return (
			<React.Fragment>
				<div
					class="modal howtoplaygame"
					id="howtoplay"
					role="dialog"
					style={{ display: "block", paddingLeft: "0px" }}
				>
					<div class="modal-dialog modal-lg">
						<div class="modal-content">
							<div class="modal-header">
								<button onClick={this.props.close} class="close">
									×
								</button>
								<h4 class="modal-title">How to Play</h4>
							</div>
							<div class="modal-body">
								<div class="inner">
									<div class="innertop">
										<p>
											Each Player needs to buy at least 1 Ticket to participate
											in the game. When the game begins, numbers are drawn in a
											random order, and the board on the right shows all the
											numbers in the order that they have been drawn.{" "}
										</p>
										<p>
											{" "}
											The player needs to cross or dab all the numbers that are
											drawn in the ticket, as called by the dealer. If you join
											a game late, you can still cross the numbers that have
											been called out previously. The player who crosses out the
											numbers in a winning pattern, will need to click on the
											"Claim" button. If more than one claimant, whoever is the
											fastest would be declared the Winner for that pattern.{" "}
										</p>
										<p>
											{" "}
											If your claim for a pattern is wrong, it will be treated
											as a Bogie and you would not be able to continue with the
											same ticket.{" "}
										</p>
										<p>
											{" "}
											The game ends when all the 90 numbers are drawn, or when a
											winner has been declared for all the patterns of the game,
											whichever comes first.{" "}
										</p>
									</div>
									<div class="innerbottom">
										<div class="heading"> Tambola winning patterns </div>
										<div class="content">
											<ul>
												<li class="clearfix">
													<span class="points">
														<span>Early Five </span>
													</span>{" "}
													<span class="points_txt">
														The first player to have Five Numbers crossed on the
														ticket
													</span>{" "}
												</li>
												<li class="clearfix">
													<span class="points">
														<span>Six Corners </span>
													</span>{" "}
													<span class="points_txt">
														The First and Last number of each row should be
														crossed. These numbers can be in any column. Each
														row would have two such numbers, and hence Six
														Corners{" "}
													</span>{" "}
												</li>
												<li class="clearfix">
													<span class="points">
														<span>Top Line </span>
													</span>{" "}
													<span class="points_txt">
														The first player to have all the numbers in the top
														row crossed
													</span>{" "}
												</li>
												<li class="clearfix">
													<span class="points">
														<span>Middle Line </span>
													</span>{" "}
													<span class="points_txt">
														The first player to have all the numbers in the
														middle row crossed
													</span>{" "}
												</li>
												<li class="clearfix">
													<span class="points">
														<span>Bottom Line </span>
													</span>{" "}
													<span class="points_txt">
														The first player to have all the numbers in the
														bottom row crossed
													</span>{" "}
												</li>
												<li class="clearfix">
													<span class="points">
														<span>Left Half House</span>
													</span>{" "}
													<span class="points_txt">
														{" "}
														The first player to have all the numbers between
														1-45 crossed on the ticket{" "}
													</span>{" "}
												</li>
												<li class="clearfix">
													<span class="points">
														<span>Right Half House</span>
													</span>{" "}
													<span class="points_txt">
														The first player to have all the numbers between
														46-90 crossed on the ticket
													</span>{" "}
												</li>
												<li class="clearfix">
													<span class="points">
														<span>All Even </span>
													</span>{" "}
													<span class="points_txt">
														The first player to have all the even numbers
														crossed on the ticket such as 2,4,6...
													</span>{" "}
												</li>
												<li class="clearfix">
													<span class="points">
														<span>All Odd </span>
													</span>{" "}
													<span class="points_txt">
														The first player to have all the odd numbers crossed
														on the ticket such as 1,3,5...
													</span>{" "}
												</li>
												<li class="clearfix">
													<span class="points">
														<span>Full House </span>
													</span>{" "}
													<span class="points_txt">
														The first ticket which has all the 15 numbers
														crossed{" "}
													</span>{" "}
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default HowToPlay;
