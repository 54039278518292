import { GET_BALANCE } from '../actions/balanceActions';

const balanceReducer = (state = '', { type, payload }) => {
	switch (type) {
		case GET_BALANCE:
			return payload;
		default:
			return state;
	}
};
export default balanceReducer;
