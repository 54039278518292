import store from '../index';
import auth from '../../services/authService';
export var GET_GAMETICK = 'GET_GAMETICK';

function get_gameticks() {
	setTimeout(async () => {
		try {
			const { data } = await auth.gameticks();
			store.dispatch({ type: GET_GAMETICK, payload: data.success });
		} catch (error) {}
	}, 100);
}

export default get_gameticks;
