import React, { Component } from "react";
import { toast } from "react-toastify";
import user from "../../services/userservice";
import { Link } from "react-router-dom";
var Joi = require("joi-browser");

const schema = Joi.object().keys({
  email: Joi.string().email().required(),
  password: Joi.string().min(4).max(10).required(),
});
class Login extends Component {
  state = {
    email: "",
    password: "",
    buttondisabled: false,
  };
  handleChange = async (evt) => {
    await this.setState({ [evt.target.name]: evt.target.value });
  };
  login = async () => {
    await this.setState({ buttondisabled: true });
    toast.configure();
    const { email, password } = this.state;

    const validata = Joi.validate({ email, password }, schema, function (
      err,
      value
    ) {
      if (!err) return null;
      const reterr = err.details[0].message;
      return reterr;
    });
    if (!!validata) {
      toast.error(validata, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setTimeout(async () => {
        await this.setState({
          email: "",
          password: "",
          buttondisabled: false,
        });
      }, 2000);
    } else {
      try {
        const obj = {
          email: email,
          password: password,
        };
        const data = await user.login(obj);
        if (data) {
          await this.setState({ token: data });
          setTimeout(() => {
            window.location = "/transactions";
          }, 2000);
        }
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.error(ex.response.data, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setTimeout(async () => {
            await this.setState({
              email: "",
              password: "",
              buttondisabled: false,
            });
          }, 2000);
        }
      }
    }
  };
  forgotpage = () => {
    this.props.history.push("/forgot");
  };
  render() {
    return (
      <React.Fragment>
        <div class="register_top in">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal_close">
                <button type="button" class="close" onClick={this.props.close}>
                  ×
                </button>
              </div>
              <div class="signinsignup-widget">
                <div class="row">
                  <div class="col-sm-12 col-md-12">
                    <div class="register-left">
                      <div class="popup-title">
                        <h1>Login</h1>
                      </div>

                      <div class="login-form">
                        <div class="signin_username">
                          <div class="signin-form">
                            <form class="form-horizontal">
                              <div class="mck-form-group">
                                <div class="input-group">
                                  {" "}
                                  <span class="input-group-addon">
                                    <i class="fa fa-envelope" />
                                  </span>
                                  <input
                                    type="text"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    class="form-control"
                                    name="email"
                                    placeholder="Email Address"
                                  />
                                </div>
                              </div>
                              <div class="mck-form-group">
                                <div class="input-group">
                                  {" "}
                                  <span class="input-group-addon">
                                    <i class="fa fa-key fa-fw" />
                                  </span>
                                  <input
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    type="password"
                                    class="form-control"
                                    name="password"
                                    placeholder="Password"
                                  />
                                </div>
                              </div>
                              <div class="getstarted">
                                <button
                                  onClick={this.login}
                                  type="button"
                                  class="btn btn-started"
                                  disabled={this.state.buttondisabled}
                                >
                                  Login
                                </button>
                              </div>
                            </form>
                          </div>
                          <div class="forgot-password">
                            <Link to="/forgot" onClick={this.props.close}>
                              <span>Forgot Password?</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
