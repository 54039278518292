import React, { Component } from "react";
import { toast } from "react-toastify";
import users from "../../services/userservice";
var Joi = require("joi-browser");

const schema = Joi.object().keys({
  name: Joi.string().min(4).max(20).required(),
  email: Joi.string().email().required(),
  password: Joi.string().min(4).max(10).required(),
});
class Register extends Component {
  state = {
    name: "",
    email: "",
    password: "",
    buttondisabled: false,
  };
  handleChange = async (evt) => {
    await this.setState({ [evt.target.name]: evt.target.value });
  };
  register = async () => {
    await this.setState({ buttondisabled: true });
    toast.configure();
    const { name, email, password } = this.state;
    const validata = Joi.validate({ name, email, password }, schema, function (
      err,
      value
    ) {
      if (!err) return null;
      const reterr = err.details[0].message;
      return reterr;
    });
    if (!!validata) {
      toast.error(validata, {
        position: toast.POSITION.BOTTOM_LEFT,
      });

      setTimeout(async () => {
        await this.setState({
          name: "",
          email: "",
          password: "",
          buttondisabled: false,
        });
      }, 2000);
    } else {
      try {
        const obj = {
          name: name,
          email: email,

          password: password,
        };
        const data = await users.register(obj);

        if (data.data.success) {
          //await this.setState({ token: data.data.token });
          setTimeout(() => {
            // this.props.history.push({
            // 	pathname: "/activation",
            // 	state: { token: data.data.token },
            // });
            this.props.close();
            this.props.loginopen();
            toast.success(data.data.success, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }, 2000);
        }
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.error(ex.response.data, {
            position: toast.POSITION.BOTTOM_LEFT,
          });

          setTimeout(async () => {
            await this.setState({
              name: "",
              email: "",
              password: "",
              buttondisabled: false,
            });
          }, 2000);
        }
      }
    }
  };
  render() {
    return (
      <React.Fragment>
        <div class="register_top in">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal_close">
                <button onClick={this.props.close} type="button" class="close">
                  ×
                </button>
              </div>
              <div class="signinsignup-widget">
                <div class="row">
                  <div class="col-sm-12 col-md-12">
                    <div class="register-left">
                      <div class="popup-title">
                        <h1>Register</h1>
                      </div>

                      <div class="login-form">
                        <div class="signin_username">
                          <div class="signin-form">
                            <form class="form-horizontal">
                              <div class="mck-form-group">
                                <div class="input-group">
                                  {" "}
                                  <span class="input-group-addon">
                                    <i class="fa fa-user" />
                                  </span>
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                    placeholder="Name"
                                  />
                                </div>
                              </div>
                              <div class="mck-form-group">
                                <div class="input-group">
                                  {" "}
                                  <span class="input-group-addon">
                                    <i class="fa fa-envelope" />
                                  </span>
                                  <input
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    type="email"
                                    class="form-control"
                                    name="email"
                                    placeholder="Email"
                                  />
                                </div>
                                <span id="chkemail" />{" "}
                              </div>
                              <div class="mck-form-group">
                                <div class="input-group">
                                  {" "}
                                  <span class="input-group-addon">
                                    <i class="fa fa-key fa-fw" />
                                  </span>
                                  <input
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    type="password"
                                    class="form-control"
                                    name="password"
                                    placeholder="Password"
                                  />
                                </div>
                              </div>
                              <div class="getstarted">
                                <button
                                  type="button"
                                  onClick={this.register}
                                  class="btn btn-started"
                                  disabled={this.state.buttondisabled}
                                >
                                  Get Started
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Register;
