import http from './httpservice';
const [ apiEndpoint, tokenKey ] = [ '/users', 'token' ];

export function register(user) {
	return http.post(apiEndpoint + '/register', user);
}

export async function login(user) {
	const { data: jwt } = await http.post(apiEndpoint + '/login', user);
	try {
		await localStorage.setItem(tokenKey, jwt);
		return jwt;
	} catch (error) {}
}

export function forgot(user) {
	return http.post(apiEndpoint + '/forgot', user);
}

export function reset(user) {
	return http.post(apiEndpoint + '/reset', user);
}

export async function getgame() {
	return await http.post(apiEndpoint + '/getgame');
}

export async function lastf() {
	return await http.post(apiEndpoint + '/lastf');
}

export async function lastff() {
	return await http.post(apiEndpoint + '/lastff');
}

export default {
	login,
	register,
	forgot,
	reset,
	getgame,
	lastf,
	lastff
};
