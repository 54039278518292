import { GET_TRANS } from '../actions/transActions';

const transReducer = (state = [], { type, payload }) => {
	switch (type) {
		case GET_TRANS:
			return payload;
		default:
			return state;
	}
};
export default transReducer;
