import React, { Component } from "react";
import { QRCode } from "react-qr-svg";
import { toast } from "react-toastify";
import auth from "../../services/authService";
import get_trans from "../../redux/actions/transActions";
var Joi = require("joi-browser");

const schema = Joi.object().keys({
  amount: Joi.number().required(),
  txnid: Joi.string().required(),
});

class AddFunds extends Component {
  state = {
    amount: "",
    txnid: "",
    buttonsid: false,
  };
  handleChange = async (evt) => {
    await this.setState({ [evt.target.name]: evt.target.value });
  };
  addfunds = async () => {
    await this.setState({ buttonsid: true });
    toast.configure();
    const { amount, txnid } = this.state;

    const validata = Joi.validate({ amount, txnid }, schema, function (
      err,
      value
    ) {
      if (!err) return null;
      const reterr = err.details[0].message;

      return reterr;
    });
    if (!!validata) {
      toast.error(validata, {
        position: toast.POSITION.BOTTOM_LEFT,
      });

      setTimeout(async () => {
        await this.setState({
          amount: "",
          txnid: "",
          buttonsid: false,
        });
      }, 2000);
    } else {
      if (this.state.amount < 25) {
        toast.error("Minimum Deposit Amount is 25/-", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setTimeout(async () => {
          await this.setState({ amount: "", txnid: "", buttonsid: false });
        }, 3000);
      } else {
        try {
          const obj = {
            amount: this.state.amount,
            txid: this.state.txnid,
          };
          const dat = await auth.deposit(obj);

          if (dat.data.success) {
            await this.setState({ amount: "", txnid: "", buttonsid: false });
            get_trans();
            toast.success(
              "Your request has been received, It will be processed soon !",
              {
                position: toast.POSITION.BOTTOM_LEFT,
              }
            );
            this.props.history.push("/transactions");
          }
        } catch (ex) {
          if (ex.response && ex.response.status === 400) {
            toast.error(ex.response.data, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setTimeout(async () => {
              await this.setState({ amount: "", txnid: "", buttonsid: false });
            }, 2000);
          }
        }
      }
    }
  };
  render() {
    return (
      <React.Fragment>
        <section id="mid-section add">
          <div class="container vertical-center">
            <div className="card mt-4">
              <div className="small-shadow">
                <div className=" col-md-12">
                  <div class="row mt-4 mb-4  p-30">
                    <div class="col-md-5 text-center">
                      <div className="qr_container">
                        <div className="qr_container_info">
                          <div className="qr_code">
                            <QRCode
                              bgColor="#FFFFFF"
                              fgColor="#000000"
                              level="Q"
                              className="receiveqr"
                              value="upi://pay?pa=techlover@apl&pn=Techlover"
                              style={{ width: 216 }}
                            />
                          </div>

                          <div className="bottomtext">
                            <h4 className="subtitletext">
                              UPI ID : techlover@apl
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 brleft" />
                    <div class="col-md-6 funds">
                      <div class="signin_username">
                        <div class="signin-form">
                          <form class="form-horizontal" role="form">
                            <div class="mck-form-group">
                              <div class="input-group input-group-lg">
                                {" "}
                                <span class="input-group-addon">
                                  <i class="fas fa-rupee-sign fa-lg" />
                                </span>
                                <input
                                  type="text"
                                  class="form-control"
                                  value={this.state.amount}
                                  onChange={this.handleChange}
                                  name="amount"
                                  placeholder="Enter Amount"
                                />
                              </div>
                            </div>
                            <div class="mck-form-group">
                              <div class="input-group input-group-lg">
                                {" "}
                                <input
                                  type="text"
                                  class="form-control"
                                  name="txnid"
                                  value={this.state.txnid}
                                  onChange={this.handleChange}
                                  placeholder="Enter TransactionID"
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div>
                        <span>Note: Minimum Deposit Amount is 25/-</span>
                      </div>

                      <div class="claim-nowleft text-center mt-4">
                        <button
                          onClick={() => this.addfunds()}
                          type="button"
                          class="btn btn-claim"
                          disabled={this.state.buttonsid}
                        >
                          Add Funds
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default AddFunds;
