import axios from "axios";
import { toast } from "react-toastify";

axios.defaults.baseURL = "https://api.crazyoffers.today/game/api";

axios.interceptors.response.use(null, error => {
	const expectedError =
		error.response &&
		error.response.status >= 400 &&
		error.response.status < 500;

	if (!expectedError) {
		toast.error("No Network, Please Connect to Internet");
	}

	return Promise.reject(error);
});
async function setJwt(jwt) {
	axios.defaults.headers.common["x-auth-token"] = jwt;
}

export default {
	get: axios.get,
	post: axios.post,
	put: axios.put,
	delete: axios.delete,
	setJwt,
};
