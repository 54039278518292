import React, { Component } from "react";
import logo from "../assets/images/logo.png";
import responsivebtn from "../assets/images/responsive-btn.png";
import smalllogo from "../assets/images/mck-logo-small.png";
import Navbar from "./navbar";
import Modal from "react-responsive-modal";
import Register from "../components/register/register";
import Login from "../components/login/login";
import { connect } from "react-redux";
import update_auth from "../redux/actions/authActions";
import auth from "../services/authService";
import avatar from "../assets/images/avatar.png";
import { Link } from "react-router-dom";
class Header extends Component {
  state = {
    registeropen: false,
    loginopen: false,
    logoutdrop: false,
  };
  componentDidMount = () => {
    update_auth();
  };

  onOpenregisterModal = () => {
    this.setState({ registeropen: true });
  };

  onCloseregisterModal = () => {
    this.setState({ registeropen: false });
  };
  onOpenloginModal = () => {
    this.setState({ loginopen: true });
  };

  onCloseloginModal = () => {
    this.setState({ loginopen: false });
  };
  logoutdropdown = async () => {
    await this.setState({ logoutdrop: !this.state.logoutdrop });
  };
  logout = () => {
    auth.logout();
    window.location = "/";
  };
  render() {
    return (
      <React.Fragment>
        <div id="container">
          <div class="mck-mobile-header clearfix">
            {/* <div class="menu-btn">
							<img
								src={responsivebtn}
								width="30"
								height="22"
								alt="My Cash Kit"
							/>
						</div> */}

            <div>
              <div class="mb-logo ml-2">
                <a>
                  <h4 style={{ color: "white" }}>CG-HOUSIE</h4>
                </a>
              </div>
              {!auth.getCurrentUser() ? (
                <div class="signin-signup">
                  <ul class="clearfix">
                    <li
                      class="cursor-pointer"
                      onClick={this.onOpenregisterModal}
                    >
                      <a>
                        <i class="fa fa-user-plus" aria-hidden="true" />{" "}
                        Register
                      </a>
                    </li>
                    <li
                      class="cursor-pointer ml-4"
                      onClick={this.onOpenloginModal}
                    >
                      <a>
                        <i class="fa fa-sign-in-alt" aria-hidden="true" /> Sign
                        in
                      </a>
                    </li>
                  </ul>
                </div>
              ) : (
                <div class="user-right">
                  <div class="navbar-custom-menu clearfix">
                    <ul class="nav navbar-nav">
                      {/* <li class="prblem-report">
												<a target="_blank">
													{" "}
													<span class="report">
														<i class="fa fa-question"></i>Report a Problem
													</span>
												</a>
											</li> */}

                      <li
                        onClick={this.logoutdropdown}
                        className="dropdown user user-menu"
                      >
                        {" "}
                        <a class="dropdown-toggle clearfix">
                          <img
                            src={avatar}
                            class="user-image"
                            alt="User Image"
                          />
                          <span class="hidden-xs username capitalize">
                            <i class="fa fa-caret-down spaceset m-0" />
                          </span>{" "}
                        </a>
                        {this.state.logoutdrop ? (
                          <ul class="dropdown-menu">
                            <li>
                              <Link to="/tambola">
                                <i class="fa fa-money-check" />
                                Housie
                              </Link>
                            </li>
                            <li>
                              <Link to="/addfunds">
                                <i class="fa fa-money-check" />
                                Add Funds
                              </Link>
                            </li>
                            <li>
                              <Link to="/transactions">
                                <i class="fa fa-money-check" />
                                Transactions
                              </Link>
                            </li>

                            <li>
                              <Link to="/lastgamewinners">
                                <i class="fa fa-money-check" />
                                Last Win
                              </Link>
                            </li>
                            <li>
                              <Link to="/withdraw">
                                <i class="fa fa-money-check" />
                                Withdraw
                              </Link>
                            </li>

                            <li>
                              <Link to="/changepassword">
                                <i class="fa fa-cog" /> Change Password
                              </Link>
                            </li>

                            <li onClick={() => this.logout()}>
                              <a>
                                <i class="fa fa-sign-out-alt" /> Log Out
                              </a>
                            </li>
                          </ul>
                        ) : null}
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
          <header class="mck-desktop-header">
            <div class="page_header">
              <div class="container">
                <div class="logo-left">
                  <a>
                    {/* <img src={logo} alt="My Cash Kit" /> */}
                    <h4 style={{ color: "white" }}>CG-HOUSIE</h4>
                  </a>
                </div>
                {!auth.getCurrentUser() ? (
                  <div class="signin-signup">
                    <ul class="clearfix">
                      <li
                        class="cursor-pointer"
                        onClick={this.onOpenregisterModal}
                      >
                        <a>
                          <i class="fa fa-user-plus" aria-hidden="true" />{" "}
                          Register
                        </a>
                      </li>
                      <li
                        class="cursor-pointer"
                        onClick={this.onOpenloginModal}
                      >
                        <a>
                          <i class="fa fa-sign-in-alt" aria-hidden="true" />{" "}
                          Sign in
                        </a>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div class="user-right">
                    <div class="navbar-custom-menu clearfix">
                      <ul class="nav navbar-nav">
                        <li class="prblem-report">
                          <Link to="/tambola">
                            {" "}
                            <span class="report">Housie</span>
                          </Link>
                        </li>
                        <li class="prblem-report">
                          <Link to="/addfunds">
                            {" "}
                            <span class="report">Add Funds</span>
                          </Link>
                        </li>
                        <li class="prblem-report">
                          <Link to="/withdraw">
                            {" "}
                            <span class="report">Withdraw</span>
                          </Link>
                        </li>
                        <li class="prblem-report">
                          <Link to="/transactions">
                            {" "}
                            <span class="report">Transactions</span>
                          </Link>
                        </li>
                        <li class="prblem-report">
                          <Link to="/lastgamewinners">
                            <span class="report">Last Win</span>
                          </Link>
                        </li>
                        <li
                          onClick={this.logoutdropdown}
                          className="dropdown user user-menu"
                        >
                          {" "}
                          <a class="dropdown-toggle clearfix">
                            <img
                              src={avatar}
                              class="user-image"
                              alt="User Image"
                            />
                            <span class="hidden-xs username capitalize">
                              {this.props.auth.name}{" "}
                              <i class="fa fa-caret-down spaceset" />
                            </span>{" "}
                          </a>
                          {this.state.logoutdrop ? (
                            <ul class="dropdown-menu">
                              <li>
                                <Link to="/changepassword">
                                  <i class="fa fa-cog" /> Change Password
                                </Link>
                              </li>

                              <li onClick={() => this.logout()}>
                                <a>
                                  <i class="fa fa-sign-out-alt" /> Log Out
                                </a>
                              </li>
                            </ul>
                          ) : null}
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </header>
        </div>
        {/* Register Modal */}
        <Modal
          open={this.state.registeropen}
          onClose={this.onCloseregisterModal}
          showCloseIcon={false}
          center
        >
          <Register
            loginopen={this.onOpenloginModal}
            close={this.onCloseregisterModal}
          />
        </Modal>
        <Modal
          open={this.state.loginopen}
          onClose={this.onCloseloginModal}
          showCloseIcon={false}
          center
        >
          <Login close={this.onCloseloginModal} />
        </Modal>
        {/* <Navbar /> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Header);
