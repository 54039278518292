import jwtDecode from "jwt-decode";
import http from "./httpservice";
const [apiEndpoint, tokenKey] = ["/userauth", "token"];

http.setJwt(getJwt());

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export async function changepass(obj) {
  return await http.post(apiEndpoint + "/changepass", obj);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    const some = jwtDecode(jwt);
    return some;
  } catch (ex) {
    return null;
  }
}

export async function getbalance() {
  return await http.post(apiEndpoint + "/balance");
}

export async function deposit(obj) {
  return await http.post(apiEndpoint + "/deposit", obj);
}
export async function withdraw(obj) {
  return await http.post(apiEndpoint + "/withdraw", obj);
}
export async function getarr(gameno) {
  return await http.post(apiEndpoint + "/getarr", { gameno });
}

export async function selseq(ticketid) {
  return await http.post(apiEndpoint + "/selseq", { ticketid });
}

export async function seltick(obj) {
  return await http.post(apiEndpoint + "/seltick", obj);
}

export async function genticket(obj) {
  return await http.post(apiEndpoint + "/generateticket", obj);
}

export async function appealwin(obj) {
  return await http.post(apiEndpoint + "/chkwin", obj);
}

export async function trans() {
  return await http.post(apiEndpoint + "/trans");
}

export async function gameticks() {
  return await http.post(apiEndpoint + "/getticks");
}
export async function canceltick(gameid) {
  return await http.post(apiEndpoint + "/canceltick", { gameid });
}
export default {
  logout,
  canceltick,
  getCurrentUser,
  changepass,
  getbalance,
  deposit,
  getarr,
  selseq,
  seltick,
  genticket,
  appealwin,
  trans,
  gameticks,
  withdraw,
};
