import React, { Component } from "react";
import { connect } from "react-redux";
import get_balance from "../../redux/actions/balanceActions";
import get_trans from "../../redux/actions/transActions";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

class Transactions extends Component {
  state = {};
  componentDidMount = async () => {
    if (this.props.trans.length <= 0) {
      await get_trans();
    }
    if (!this.props.balance) {
      await get_balance();
    }
  };

  render() {
    const { balance, trans } = this.props;
    return (
      <React.Fragment>
        <section id="mid-section">
          <div class="container">
            <div class="row mt-4 mb-4">
              <div class="col-md-4">
                <h5 style={{ flexDirection: "row" }}>
                  <span style={{ fontSize: "18px" }}>Available Balance :</span>{" "}
                  <span style={{ fontSize: "18px" }}>
                    {balance ? balance : 0}
                  </span>
                </h5>
              </div>
              <div class="col-md-4 float-right">
                <div class="addfunds">
                  <div class="claim-nowleft fr">
                    <button
                      onClick={() => this.props.history.push("addfunds")}
                      type="button"
                      class="btn btn-claim3"
                    >
                      Add Funds
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-4 float-right">
                <div class="addfunds">
                  <div class="claim-nowleft fr">
                    <button
                      onClick={() => this.props.history.push("/withdraw")}
                      type="button"
                      class="btn btn-claim"
                    >
                      Withdraw
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div id="play-tambola">
                  <div class="tambola-result">
                    <div class="tambolawinners-details">
                      <div class="tambola-winners-title">
                        <h1>Recent Transactions</h1>
                      </div>
                      <div class="last-gamewinners">
                        <Table className="table table-striped table-border tambola-winners-list">
                          <Thead className="bg-blue">
                            <Tr>
                              <Th className="trth"> S.NO</Th>
                              <Th style={{ textAlign: "center" }}>
                                Transaction ID
                              </Th>
                              <Th style={{ textAlign: "center" }}>Type</Th>
                              <Th>Amount</Th>
                              <Th>Comment</Th>
                              <Th>Status</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {trans.length > 0 ? (
                              trans.map((item, i) => {
                                return (
                                  <Tr key={i} className="border_bottom_theme">
                                    <Td class="trth"> {i + 1}</Td>
                                    <Td style={{ textAlign: "center" }}>
                                      {item.txid}
                                    </Td>
                                    <Td style={{ textAlign: "center" }}>
                                      {item.type}
                                    </Td>
                                    <Td>
                                      {item.type === "Withdraw" ||
                                      item.type === "Tickets" ? (
                                        <span style={{ color: "red" }}>
                                          {item.amount}
                                        </span>
                                      ) : (
                                        <span style={{ color: "green" }}>
                                          {item.amount}
                                        </span>
                                      )}
                                    </Td>
                                    <Td>
                                      {item.comment
                                        ? item.comment.substr(0, 15)
                                        : null}
                                      ...
                                    </Td>
                                    <Td> {item.status}</Td>
                                  </Tr>
                                );
                              })
                            ) : (
                              <Tr>
                                <Td />
                                <Td className="text-center">
                                  No Transactions Available
                                </Td>
                                <Td />
                              </Tr>
                            )}
                          </Tbody>
                          {}
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    balance: state.balance,
    trans: state.trans,
  };
};

export default connect(mapStateToProps)(Transactions);
