import React, { Component } from "react";
import Header from "../../common/header";
import users from "../../services/userservice";
import { toast } from "react-toastify";
var Joi = require("joi-browser");

const schema = Joi.object().keys({
	password: Joi.string().min(8).max(10).required(),
	confirmpassword: Joi.any()
		.valid(Joi.ref("password"))
		.required()
		.options({ language: { any: { allowOnly: "must match password" } } }),
});
class ResetPassword extends Component {
	state = {
		password: "",
		confirmpassword: "",
		token: "",
	};
	componentDidMount = () => {
		this.setState({ token: this.props.match.params.id });
		window.history.replaceState(null, null, window.location.pathname);
	};
	handleChange = async (evt) => {
		await this.setState({ [evt.target.name]: evt.target.value });
	};
	create = async () => {
		toast.configure();
		const { password, confirmpassword } = this.state;
		const validata = Joi.validate(
			{ password, confirmpassword },
			schema,
			function (err, value) {
				if (!err) return null;
				const reterr = err.details[0].message;
				return reterr;
			},
		);
		if (!!validata) {
			toast.error(validata, {
				position: toast.POSITION.BOTTOM_LEFT,
			});

			setTimeout(async () => {
				await this.setState({
					password: "",
					confirmpassword: "",
				});
			}, 2000);
		} else {
			try {
				const obj = {
					token: this.props.match.params.id,
					password: password,
				};
				const data = await users.reset(obj);
				if (data.data.success) {
					toast.success(data.data.success, {
						position: toast.POSITION.BOTTOM_LEFT,
					});
					this.props.history.push("/home");
				}
			} catch (error) {}
		}
	};
	render() {
		return (
			<React.Fragment>
				<div class="container googleadd-top">
					<div class="row">
						<div class="col-md-12">
							<section id="add-vertical" />
						</div>
					</div>
				</div>
				<section id="mid-section justifycenter">
					<div class="container">
						<div class="register-main">
							<div class="signin-row clearfix">
								<div class="left-squareadd" id="" />

								<div class="register-box">
									<div class="registerbox-inner">
										<div class="register-form">
											<div class="forgot-password">
												<div id="error_msg" />
												<h1>Reset Password</h1>
												<div class="forgotpass-tips">
													<p>If you have forgotten your password,</p>
													<p>
														we can send an email with instructions to reset it.
													</p>
												</div>
												<div class="fgt-formbottom">
													<div class="mck-form-group">
														<div class="input-group">
															{" "}
															<span class="input-group-addon">
																<i class="fa fa-key fa-fw" />
															</span>
															<input
																type="password"
																class="form-control"
																name="password"
																value={this.state.password}
																onChange={this.handleChange}
																placeholder="Enter New Password"
															/>
														</div>
													</div>
													<div class="mck-form-group">
														<div class="input-group">
															{" "}
															<span class="input-group-addon">
																<i class="fa fa-key fa-fw" />
															</span>
															<input
																type="password"
																class="form-control"
																name="confirmpassword"
																onChange={this.handleChange}
																value={this.state.confirmpassword}
																placeholder="Re-Enter Password"
															/>
														</div>
													</div>
													<div class="getstarted">
														<button
															onClick={this.create}
															class="btn btn-started"
														>
															Reset Password
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="right-squareadd text-center" />
							</div>
						</div>
					</div>
				</section>
			</React.Fragment>
		);
	}
}

export default ResetPassword;
