import store from "../index";
import user from "../../services/userservice";
export var GET_FINGAMS = "GET_FINGAMS";

function get_fingams() {
  setTimeout(async () => {
    try {
      const { data } = await user.lastff();

      store.dispatch({ type: GET_FINGAMS, payload: data });
    } catch (error) {}
  }, 100);
}

export default get_fingams;
