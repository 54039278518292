import store from '../index';
import user from '../../services/userservice';
export var GET_PENRESGAME = 'GET_PENRESGAME';

function get_penresgame() {
	setTimeout(async () => {
		try {
			const { data } = await user.getgame();
			store.dispatch({ type: GET_PENRESGAME, payload: data.success });
		} catch (error) {}
	}, 100);
}

export default get_penresgame;
