import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import get_fingams from "../../redux/actions/fingamActions";

class LastgameWinners extends Component {
  state = {};
  componentDidMount = async () => {
    if (this.props.fingams.length <= 0) {
      get_fingams();
    }
  };
  render() {
    const fingams = this.props.fingams.success;
    const toppers = this.props.fingams.toppers;

    return (
      <React.Fragment>
        <section id="mid-section">
          <div class="container mt-4">
            <div class="row ">
              <div class="col-md-10">
                <div id="play-tambola">
                  <div class="tambola-result">
                    <div class="tambolawinners-details">
                      <div class="tambola-winners-title">
                        <h1>Last Game Winners</h1>
                      </div>
                      <div class="last-gamewinners">
                        <Table className="table table-striped table-border tambola-winners-list">
                          <Thead className="bg-blue">
                            <Tr>
                              <Th style={{ width: "12%" }}>Game ID</Th>
                              <Th>Fast Five</Th>
                              <Th>Top Line</Th>
                              <Th>Middle Line</Th>
                              <Th>Bottom </Th>
                              <Th>FullHouse</Th>
                              <Th>Status </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {fingams && fingams.length > 0 ? (
                              fingams.map((item, i) => {
                                return (
                                  <Tr key={i} className="border_bottom_theme">
                                    <Td
                                      style={{
                                        textAlign: "center",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {" "}
                                      {item.gameid}
                                    </Td>
                                    {item.winners
                                      ? item.winners.map((it, index) => {
                                          return (
                                            <Td
                                              style={{
                                                textAlign: "center",
                                                fontSize: "13px",
                                              }}
                                            >
                                              {it.name}
                                            </Td>
                                          );
                                        })
                                      : null}
                                    <Td
                                      style={{
                                        textAlign: "center",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {item.status}
                                    </Td>
                                  </Tr>
                                );
                              })
                            ) : (
                              <Tr>
                                <Td />
                                <Td className="text-center">
                                  No Transactions Available
                                </Td>
                                <Td />
                              </Tr>
                            )}
                          </Tbody>
                          {}
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div id="play-tambola">
                  <div class="tambola-result">
                    <div class="tambolawinners-details">
                      <div class="tambola-winners-title">
                        <h1>Top 5 Winners</h1>
                      </div>
                      <div class="last-gamewinners">
                        <Table className="table table-striped table-border tambola-winners-list">
                          <Thead className="bg-blue">
                            <Tr>
                              <Th style={{ width: "12%" }}>TOP Winners</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {toppers && toppers.length > 0 ? (
                              toppers.map((item, i) => {
                                return (
                                  <Tr key={i} className="border_bottom_theme">
                                    <Td style={{ textAlign: "center" }}>
                                      {item}
                                    </Td>
                                  </Tr>
                                );
                              })
                            ) : (
                              <Tr>
                                <Td />
                                <Td className="text-center">No Winners</Td>
                                <Td />
                              </Tr>
                            )}
                          </Tbody>
                          {}
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fingams: state.fingams,
  };
};

export default connect(mapStateToProps)(LastgameWinners);
