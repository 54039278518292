import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/slider.css";
import "../src/assets/css/bootstrap-social.css";
import "../src/assets/css/global.css";
import "../src/assets/css/mck-responsive.css";
import "../src/assets/css/mobile-menu.css";
import "../src/assets/css/pushy.css";
import "../src/assets/css/style.css";
import "../src/assets/css/signin_register.css";
import "../src/assets/css/signup.css";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
